import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.page.html',
  styleUrls: ['./product-details.page.scss'],
})


export class ProductDetailsPage implements OnInit {

  productNameFromRoute;
  currentProductDetails;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    this.productNameFromRoute = String(routeParams.get('productName'));

    console.log(this.productNameFromRoute)
    this.currentProductDetails = this.products[this.productNameFromRoute]
    console.log(this.currentProductDetails)
  }

  products= {
    "ChocolateStrawRetailBox":{
      "shape":"straw",
      "size":"8 mm",
      "flavor":"Chocolate",
      "packaging":"Retail Box",
      "quantity": 24,
      "otherFlavors": ["Chocolate", "Vanilla", "Ginger-Cinnamon", "Lemon-Mint", "Coconut"],
      "images":[]
    },
    "VanillaStrawRetailBox":{
      "shape":"straw",
      "size":"8 mm",
      "flavor":"Chocolate",
      "packaging":"Retail Box",
      "quantity": 24,
      "otherFlavors": ["Chocolate", "Vanilla", "Ginger-Cinnamon", "Lemon-Mint", "Coconut"],
      "images":[]
    },
    "AssortedStrawRetailBox":{
      "shape":"straw",
      "size":"8 mm",
      "flavor":"Chocolate",
      "packaging":"Retail Box",
      "quantity": 24,
      "otherFlavors": ["Chocolate", "Vanilla", "Ginger-Cinnamon", "Lemon-Mint", "Coconut"],
      "images":[]
    },
    "LemonMintStrawRetailBox":{
      "shape":"straw",
      "size":"8 mm",
      "flavor":"Chocolate",
      "packaging":"Retail Box",
      "quantity": 24,
      "otherFlavors": ["Chocolate", "Vanilla", "Ginger-Cinnamon", "Lemon-Mint", "Coconut"],
      "images":[]
    },
    "GingerCinnamonStrawRetailBox":{
      "shape":"straw",
      "size":"8 mm",
      "flavor":"Chocolate",
      "packaging":"Retail Box",
      "quantity": 24,
      "otherFlavors": ["Chocolate", "Vanilla", "Ginger-Cinnamon", "Lemon-Mint", "Coconut"],
      "images":[]
    },
    "CoconutStrawRetailBox":{
      "shape":"straw",
      "size":"8 mm",
      "flavor":"Chocolate",
      "packaging":"Retail Box",
      "quantity": 24,
      "otherFlavors": ["Chocolate", "Vanilla", "Ginger-Cinnamon", "Lemon-Mint", "Coconut"],
      "images":[]
    },
  }
}
